body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--app-background-main);
    color: white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

:root {
    /* Palette */
    --light-grey: #484e58;
    --grey: #353a43;
    --darkgrey: #282c34;
    --darkergrey: #18191c;
    --sky-light: #8ee6ff;
    --sky: #61dafb;
    --sky-alt: #14d6ff;
    --sky-dark:#2f86a9;
    --purple: #ad61ff;
    --purple-dark: #8f4dd6;
    --pumpkin: #fe7f2d;
    --pumpkin-dark: #684d3b;
    --sunglow: #fcca46;
    --sunglow-dark: #63593a;
    --zomp: #619b8a;
    --zomp-dark: #467668;
    --olivine: #8fbe60;
    --olivine-dark: #7eac4f;
    --olivine-darker: #556841;
    --crimson: #f14343;
    --crimson-dark: #794b4b;
    --crimson-light: #f77373;
    --mindaro: #c5d86d;
    --mindaro-light: #d4e77e;
    --giants-orange: #f05d23;
    --darkblue: #201e50;
    --tea: #c4f1be;
    --blue: #5fb2ff;
    --taupe: rgb(118, 108, 210);

    /* Theme */

    --theme-main: var(--sky);
    --theme-alt: var(--sky-light);
    --theme-border: var(--sky-dark);

    --theme-button: var(--sky);
    --theme-button-hover: var(--sky-light);

    --theme-text: var(--sky);

    /* Backgrounds & borders */

    --app-background-main: var(--darkgrey);
    --app-background-alt: var(--grey);
    --app-background-alt2: var(--light-grey);

    --border-default: #585858;
    --border-light: #919191;

    /* Header */

    --header-background: var(--theme-main);
    --header-theme: white;
    --header-text: black;
    --header-text-theme: black;

    --tab-off-background: var(--sky-alt);
    --tab-off-border: var(--theme-border);
    --tab-off-text: black;
    --tab-alt-background: var(--grey);
    --tab-alt-border: var(--tab-alt-background);
    --tab-alt-text: white;
    --tab-on-background: var(--app-background-main);
    --tab-on-border: var(--tab-on-background);
    --tab-on-text: white;

    /* Planning */

    /*v2*/
    --day-header-background: #902923;
    --day-header-background-alt: #a22c29;
    --day-header-background-today: #61dafb;
    --day-header-text: white;
    --day-header-text-today: black;
    --day-column-background: #502826;
    --day-column-background-alt: #5f2929;
    --day-column-background-today: #315c68;

    --planning-resa-background: var(--grey);
    --planning-resa-header-background: #70f1ff;
    --planning-resa-border: var(--border-light);
    --planning-resa-highlight-background: #93f4ff;
    --planning-resa-highlight-border: var(--theme-border);




    /* Theme - Christmas */

    /*--theme-main: var(--crimson);
    --theme-alt: var(--crimson-light);
    --theme-border: var(--crimson-dark);

    --theme-button: #56ce4c;
    --theme-button-hover: #45b23b;

    --theme-text: #56ce4c;

    /* Backgrounds & borders */

    /*--app-background-main: var(--darkgrey);
    --app-background-alt: var(--grey);
    --app-background-alt2: var(--light-grey);

    --border-default: #585858;
    --border-light: #919191;

    /* Header */

    /*--header-background: var(--theme-main);
    --header-theme: white;
    --header-text: black;
    --header-text-theme: black;

    --tab-off-background: var(--crimson);
    --tab-off-border: var(--theme-border);
    --tab-off-text: white;
    --tab-alt-background: var(--grey);
    --tab-alt-border: var(--tab-alt-background);
    --tab-alt-text: white;
    --tab-on-background: var(--app-background-main);
    --tab-on-border: var(--tab-on-background);
    --tab-on-text: white;

    /* Planning */

    /*--day-header-background: #bb4949;
    --day-header-background-alt: #e25555;
    --day-header-background-today: #74b557;
    --day-header-text: black;
    --day-column-background: rgb(92, 42, 42);
    --day-column-background-alt: rgb(101, 58, 58);
    --day-column-background-today: #567847;

    --planning-resa-background: var(--grey);
    --planning-resa-header-background: var(--mindaro);
    --planning-resa-border: var(--border-light);
    --planning-resa-highlight-background: var(--mindaro-light);
    --planning-resa-highlight-border: var(--olivine-dark);*/
}